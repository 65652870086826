<template>
  <div>
    <div class="box">
      <div class="columns is-multiline" v-if="data.valuationID">
        <div class="column is-12">
          <img
            :src="require('core/img/logos/misc/glasses.png')"
            width="128"
            alt="Glass's"
          />
        </div>
        <div class="column is-6 has-text-grey">Vehicle description</div>
        <div class="column is-6 has-text-right">
          {{ data.trimDetails || 'N/A' }} {{ data.modelDetails }}
        </div>
        <div class="column is-6 has-text-grey">Insurance group</div>
        <div class="column is-6 has-text-right">
          {{
            data.insuranceGroup && data.insuranceGroup !== '00'
              ? data.insuranceGroup
              : 'N/A'
          }}
        </div>
        <div class="column is-6 has-text-grey">Glass's reference</div>
        <div class="column is-6 has-text-right">
          {{ data.retailPriceCode || 'N/A' }}
        </div>
        <div class="column is-6 has-text-grey">Average mileage</div>
        <div class="column is-6 has-text-right">
          {{ data.averageMileage ? formatNumber(data.averageMileage) : 'N/A' }}
        </div>
        <div class="column is-6 has-text-grey">Current retail</div>
        <div class="column is-6 has-text-right">
          {{ data.retailPriceCode ? formatMoney(currentRetail) : 'N/A' }}
        </div>
        <div class="column is-6 has-text-grey">Trade (average mileage)</div>
        <div class="column is-6 has-text-right">
          {{ data.valueBasicTrade ? formatMoney(data.valueBasicTrade) : 'N/A' }}
        </div>
        <div class="column is-6 has-text-grey">
          Trade ({{ formatNumber(mileage) }} miles)
        </div>
        <div class="column is-6 has-text-right">
          {{ data.adjustedValue ? formatMoney(data.adjustedValue) : 'N/A' }}
        </div>
        <div class="column is-6 has-text-grey">New Price</div>
        <div class="column is-6 has-text-right">
          {{ data.retailPriceCode ? formatMoney(newPrice) : 'N/A' }}
        </div>
      </div>
      <div class="columns" v-else>
        <div class="column">
          <div class="field">
            <img
              :src="require('core/img/logos/misc/glasses.png')"
              width="128"
              alt="Glass's"
            />
          </div>
          <p>
            Unfortunately, it was not possible to value this vehicle. You will
            not be charged for this valuation
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoney, formatNumber } from 'accounting'
export default {
  name: 'AutoCheckGlass',
  props: {
    data: {
      type: Object,
      required: true
    },
    mileage: {
      type: Number,
      required: true
    }
  },
  methods: {
    formatMoney(value) {
      return formatMoney(value, '£', 0)
    },
    formatNumber(value) {
      return formatNumber(value)
    }
  },
  computed: {
    newPrice() {
      const regex = /P(.*?)G/g
      return regex.exec(this.data.retailPriceCode)[1]
    },
    currentRetail() {
      const regex = /R(.*?)$/g
      return regex.exec(this.data.retailPriceCode)[1]
    }
  }
}
</script>
